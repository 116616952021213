import Parse from "/server/parse-server.js";
import locationId from "/server/parse-location.js";
import { LocationObject } from "/server/parse-location.js";
import { goToNextPage } from "/reusable/navigation.js";
import { orderQuickOrder } from "/reusable/reorder.js";
import { getToastItem , getToastGroup } from "/toast/toast-menu.js";
import * as branch from 'branch-sdk';
import { formatPhone } from "/reusable/string.js";
import { isMobileDevice } from "/reusable/device-type.js";
require('dotenv').config();

"use strict";
window.Webflow ||= [];
window.Webflow.push(async () => { 

   const verifyModal = document.getElementById('verifyModal');
   const verifyTopLabel = document.getElementById('verifyTopLabel');
   const verifyPhoneTextField = document.getElementById('verifyPhoneTextField');
   const resendCodeButton = document.getElementById('resendCodeButton');
   const verifySmsButton = document.getElementById('verifySmsButton');
   const closeVerifyButton = document.getElementById('closeVerifyButton');

   var initialoffer;
   var initialamount;

   var locationObject = await LocationObject();

   // MARK: - BRANCH LINK
   branch.init(process.env.BRANCH_KEY, function(err, data) {
      const linkData = JSON.parse(data.data);
		if (linkData != null) {
			handleDeeplink(linkData);
		}
   });

   function handleDeeplink(linkData) {

      const deepLinkPath = linkData.$deeplink_path;
      if (deepLinkPath != null) {

         const currentUser = Parse.User.current();
         if (deepLinkPath.includes('initialoffer')) {
            handleInitialOffer();
         }

         if (deepLinkPath.includes('referid')) {
            const referid = deepLinkPath.slice(-10);
            if (currentUser.get('anonymous') == false) {
               alert('You are already signed into an account');
            } else {
               sessionStorage.setItem('referid', referid);
               goToNextPage('/create-account');
            }
         }

         if (deepLinkPath.includes('foodid')) {
            const itemGuid = linkData.$itemGuid;
            const groupGuid = linkData.$groupGuid;
            goToMenuItem(itemGuid, groupGuid);
         }
      }
   }

   function checkInitialOffer() {
      const initialoffer = sessionStorage.getItem('initial');
      if (initialoffer!= null) {
         sessionStorage.removeItem('initial');
         handleInitialOffer();
      }
   }

   function handleInitialOffer() {
      const currentUser = Parse.User.current();
      if (currentUser != null) {
         if (currentUser.get('nuberconfirmed') == true) {
            alert('You already redeemed this offer');
         } else {
            if (currentUser.get('anonymous') == true) {
               sessionStorage.setItem('initialoffer', 'true');
               goToNextPage('/create-account');
            } else {
               initialoffer = 'true';
               initialamount = locationObject.get('initialamount');
               const phone = currentUser.get('phonenumber');
               setUpPhoneVerify(phone);
            }
         }
      } else {
         sessionStorage.setItem('initialoffer', 'true');
         goToNextPage('/create-account');
      }
   }

   checkInitialOffer();

   // MARK: - ORDER OPTIONS
   const homeDeliveryButton = document.getElementById('homeDeliveryButton');
   homeDeliveryButton.addEventListener('click', function() {
      sessionStorage.setItem('startDelivery', 'true');
      goToNextPage('/order');
   });

   const homePickUpButton = document.getElementById('homePickUpButton');
   homePickUpButton.addEventListener('click', function() {
      sessionStorage.setItem('startDelivery', 'false');
      goToNextPage('/order');
   });

   const progressModal = document.getElementById('progressModal');
   const signedInSection = document.getElementById('signedInSection');
   const homeBottomAccountButton = document.getElementById('homeBottomAccountButton');
   const homeBottomOrderButton = document.getElementById('homeBottomOrderButton');

   const homeLeftPromoButton = document.getElementById('homeLeftPromoButton');
   const homeTopRightPromoButton = document.getElementById('homeTopRightPromoButton');

   const leftPromoImage = document.getElementById('leftPromoImage');
   const rightTopPromoImage = document.getElementById('rightTopPromoImage');
   const rightBottomPromoImage = document.getElementById('rightBottomPromoImage');

   function setUpPage() {

      loadPromoOffers();

      const currentUser = Parse.User.current();
      if (currentUser != null) {
         const anonymous = currentUser.get('anonymous');
         if (anonymous == false) {
            setUpUserSection();
            signedInSection.style.display = 'block';
            homeBottomAccountButton.innerText = 'REWARDS';
         }
      }

      if (isMobileDevice() == false) {
         setUpOrderDetails();
      }
   }

   function setUpUserSection() {
      const currentUser = Parse.User.current();
      const ribboncount = currentUser.get('ribboncount');
      var ribbonString = "RIBBONS";
      if (ribboncount == 1) {
           ribbonString = "RIBBON";
      }
      ribbonCountLabel.innerText = '' + ribboncount;
      ribbonsLabel.innerText = ribbonString;
      loadRewardItems();
      setUpPreviousOrders();
   }

   // MARK: - RIBBON VIEW
   const ribbonCountLabel = document.getElementById('ribbonCountLabel');
   const ribbonsLabel = document.getElementById('ribbonsLabel');

   const viewRewardsButton = document.getElementById('viewRewardsButton');
   const rewardItemsBlock = document.getElementById('rewardItemsBlock');

   var showRewards = false;
   viewRewardsButton.addEventListener('click', async () => {
       showRewards = !showRewards
       if (showRewards == false) {
           viewRewardsButton.innerText = 'SHOW REWARDS';
           rewardItemsBlock.style.display = 'none';
       } else {
           viewRewardsButton.innerText = 'CLOSE REWARDS';
           rewardItemsBlock.style.display = 'flex';
       }
       
   });

   var rewards = [];

   async function loadRewardItems() {
       try {
           rewards = await Parse.Cloud.run('loadToastRewards', {});
           setUpRewardItems();
       } catch(e) {
           alert(e.message); 
       }
   }

   function setUpRewardItems() {

       const rewardsCell = document.querySelector('[rewards="cell"]');
       const rewardsParent = rewardsCell.parentNode;

       const rewardsCells = [...document.querySelectorAll('[rewards="cell"]')];

       for (let i = 0; i < rewards.length; i++) {

           const newRewardsCell = i === 0 ? rewardsCells[0] : rewardsCells[0].cloneNode(true);

           const ribbonCountLabel = newRewardsCell.querySelector('[rewards="tibbonCountLabel"]');
           ribbonCountLabel.innerText = '' + rewards[i].get('ribbonCost');

           const itemPhoto = newRewardsCell.querySelector('[rewards="itemPhoto"]');
           itemPhoto.src = rewards[i].get('photo').url();

           const title = newRewardsCell.querySelector('[rewards="nameLabel"]');
           title.innerText = rewards[i].get('name').toUpperCase();

           rewardsParent.appendChild(newRewardsCell);
           
           newRewardsCell.addEventListener('click', () => {
               goToNextPage('/rewards');
           });
       }
   }


   // MARK: - QUICK ORDER
   const ordersModal = document.getElementById('ordersModal');
   const noOrdersDiv = document.getElementById('noOrdersDiv');
   const pastOrdersDiv = document.getElementById('pastOrdersDiv');
   const orderCountLabel = document.getElementById('orderCountLabel');
   const startOrderButton = document.getElementById('startOrderButton');

   const noQuickOrderDiv = document.getElementById('noQuickOrderDiv');
   const homeQuickOrderDiv = document.getElementById('homeQuickOrderDiv');

   const quickOrderDiv = document.getElementById('quickOrderDiv');
   const quickDateLabel = document.getElementById('quickDateLabel');
   const quickItemCount = document.getElementById('quickItemCount');
   const quickItemNamesLabel = document.getElementById('quickItemNamesLabel');
   const quickDiningOptionLabel = document.getElementById('quickDiningOptionLabel');
   const quickAddressLabel = document.getElementById('quickAddressLabel');

   const quickOrderButton = document.getElementById('quickOrderButton');
   const pastButtonDIv = document.getElementById('pastButtonDIv');
   const quickOpenIcon = document.getElementById('quickOpenIcon');
   const quickButtonDIv = document.getElementById('quickButtonDIv');
   const pastOpenIcon = document.getElementById('pastOpenIcon');
   const homePastOrdersDiv = document.getElementById('homePastOrdersDiv');
   const noPastOrderDiv = document.getElementById('noPastOrderDiv');

   const reorderPastOrderButton = document.getElementById('reorderPastOrderButton');
   const pastOrders = document.getElementById('pastOrders');
   const pastOrderCellDiv = document.getElementById('pastOrderCellDiv');

   var previousOrders = [];
   var quickOrder;
   var reorderOrder;

   async function setUpPreviousOrders() {
      previousOrders = await Parse.Cloud.run('loadPreviousOrders', {});
      if (previousOrders.length == 0) {
         noOrdersDiv.style.display = 'block';
         pastOrdersDiv.style.display = 'none';
         noPastOrderDiv.style.display = 'block';
         pastOrders.style.display = 'none';
      } else {
         noOrdersDiv.style.display = 'none';
         noPastOrderDiv.style.display = 'none';
         pastOrdersDiv.style.display = 'flex';
         pastOrders.style.display = 'block';
         orderCountLabel.innerText = 'Orders ( ' + previousOrders.length + ' )'; 
         setPreviousOrdersTable();
         setPastCollectionCells();
         //setPastOrderTable();
         quickOrder = previousOrders.find(obj => obj.get('favorite') === true);
         setQuickOrder();
         reorderOrder = previousOrders[0];
         setPastOrder();
         setPastOrderCellColor();
      }
   }

   function setQuickOrder() {
      if (quickOrder != null) {
         noQuickOrderDiv.style.display = 'none';
         quickOrderDiv.style.display = 'flex';
         quickOrderButton.style.display = 'block';

         const orderObject = quickOrder.get('orderObject');

         const openedDate = new Date(orderObject.openedDate);
         const formattedDate = openedDate.toLocaleDateString('en-US', {
               weekday: 'long', 
               month: 'short', 
               day: 'numeric'
         });

         const options = { hour: '2-digit', minute: '2-digit', hour12: true };
         const hourString = openedDate.toLocaleString('en-US', options);
         quickDateLabel.innerText = formattedDate.toUpperCase() + ' - ' + hourString;

         const selections = orderObject.checks[0].selections;

         var quantity = 0
         var itemNames = [];
         for (const selection of selections) {
               quantity += selection.quantity;
               itemNames.push(selection.displayName.toUpperCase());
         }

         if (quantity == 1) {
               quickItemCount.innerText = quantity + ' ITEM';
         } else {
               quickItemCount.innerText = quantity + ' ITEMS';
         }

         quickItemNamesLabel.innerText = itemNames.join(', ');

         const deliveryInfo = orderObject.deliveryInfo;

         if (deliveryInfo != null) {
               quickDiningOptionLabel.innerText = 'DELIVERY';
               const address = deliveryInfo.address1;
               const apt = deliveryInfo.address2;
               const city = deliveryInfo.city;
               const state = deliveryInfo.state;
               const zipCode = deliveryInfo.zipCode;
               var addressString = address + ' - ' + city + ', ' + state + ' ' + zipCode;
               if (apt != null && apt != '') {
                  addressString = address + ', ' + apt + ' - ' + city + ', ' + state + ' ' + zipCode;
               }
               quickAddressLabel.innerText = addressString.toUpperCase();
         } else {
               quickDiningOptionLabel.innerText = 'PICK UP';
               const locationobject = quickOrder.get('locationobject');
               const address = locationobject.get('address');
               const city = locationobject.get('city');
               const state = locationobject.get('state');
               const zipcode = locationobject.get('zipcode');
               const addressString = address + ' - ' + city + ', ' + state + ' ' + zipcode;
               quickAddressLabel.innerText = addressString.toUpperCase();
         }

      } else {
         noQuickOrderDiv.style.display = 'flex';
         quickOrderDiv.style.display = 'none';
         quickOrderButton.style.display = 'none';
      }
   }

   function setPastOrder() {
      if (reorderOrder != null) {
         const order = reorderOrder;

         const dateLabel = document.querySelector('[pastorder="dateLabel"]');
         const orderObject = order.get('orderObject');

         const openedDate = new Date(orderObject.openedDate);
         const formattedDate = openedDate.toLocaleDateString('en-US', {
               weekday: 'long', 
               month: 'short', 
               day: 'numeric'
         });

         const options = { hour: '2-digit', minute: '2-digit', hour12: true };
         const hourString = openedDate.toLocaleString('en-US', options);
         dateLabel.innerText = formattedDate.toUpperCase() + ' - ' + hourString;

         const selections = orderObject.checks[0].selections;

         var quantity = 0
         var itemNames = [];
         for (const selection of selections) {
               quantity += selection.quantity;
               itemNames.push(selection.displayName.toUpperCase());
         }

         const itemCountLabel = document.querySelector('[pastorder="itemCountLabel"]');
         if (quantity == 1) {
               itemCountLabel.innerText = quantity + ' ITEM';
         } else {
               itemCountLabel.innerText = quantity + ' ITEMS';
         }

         const itemNamesLabel = document.querySelector('[pastorder="itemNamesLabel"]');
         itemNamesLabel.innerText = itemNames.join(', ');

         const deliveryInfo = orderObject.deliveryInfo;

         const diningOptionLabel = document.querySelector('[pastorder="diningOptionLabel"]');
         const addressLabel = document.querySelector('[pastorder="addressLabel"]');
         if (deliveryInfo != null) {
               diningOptionLabel.innerText = 'DELIVERY';
               const address = deliveryInfo.address1;
               const apt = deliveryInfo.address2;
               const city = deliveryInfo.city;
               const state = deliveryInfo.state;
               const zipCode = deliveryInfo.zipCode;
               var addressString = address + ' - ' + city + ', ' + state + ' ' + zipCode;
               if (apt != null && apt != '') {
                  addressString = address + ', ' + apt + ' - ' + city + ', ' + state + ' ' + zipCode;
               }
               addressLabel.innerText = addressString.toUpperCase();
         } else {
               diningOptionLabel.innerText = 'PICK UP';
               const locationobject = order.get('locationobject');
               const address = locationobject.get('address');
               const city = locationobject.get('city');
               const state = locationobject.get('state');
               const zipcode = locationobject.get('zipcode');
               const addressString = address + ' - ' + city + ', ' + state + ' ' + zipcode;
               addressLabel.innerText = addressString.toUpperCase();
         }
      }
   }

   pastOrderCellDiv.addEventListener('click', () => {
      if (reorderOrder != null) {
         sessionStorage.setItem('parseOrder', JSON.stringify(reorderOrder));
         goToNextPage('/order-tracker');
      }
   });

   var quickOpen = false;
   quickButtonDIv.addEventListener('click', () => {
      quickOpen = !quickOpen
      if (quickOpen == true) {
         homeQuickOrderDiv.style.display = 'flex';
         quickOpenIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/678712710757697729ac920c_up.png'
      } else { 
         homeQuickOrderDiv.style.display = 'none';
         quickOpenIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/678712716bb44379bbc2af7b_down.png'
      }
   });

   var pastOpen = false;    
   pastButtonDIv.addEventListener('click', () => {
      pastOpen = !pastOpen
      if (pastOpen == true) {
         homePastOrdersDiv.style.display = 'block';
         pastOpenIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/678712710757697729ac920c_up.png'
      } else { 
         homePastOrdersDiv.style.display = 'none';
         pastOpenIcon.src = 'https://cdn.prod.website-files.com/674a9e2494fe039046e5bde6/678712716bb44379bbc2af7b_down.png'
      }
   });

   quickOrderButton.addEventListener('click', async () => {
      if (quickOrder != null) { 
         progressModal.style.display = 'flex';
         await orderQuickOrder(quickOrder); 
         progressModal.style.display = 'none';
         goToNextPage('/checkout');
      }   
   });

   reorderPastOrderButton.addEventListener('click', async () => {
      if (reorderOrder != null) {
         progressModal.style.display = 'flex';
         await orderQuickOrder(reorderOrder); 
         progressModal.style.display = 'none';
         goToNextPage('/checkout');
      }
   });

   function setPreviousOrdersTable() {
      const ordersCell = document.querySelector('[order="cell"]');
      const ordersParent = ordersCell.parentNode;

      const ordersCells = [...document.querySelectorAll('[order="cell"]')];

      for (let i = 0; i < previousOrders.length; i++) {

         const newOrdersCell = i === 0 ? ordersCells[0] : ordersCells[0].cloneNode(true);

         const order = previousOrders[i];

         const dateLabel = newOrdersCell.querySelector('[order="dateLabel"]');
         const orderObject = order.get('orderObject');

         const openedDate = new Date(orderObject.openedDate);
         const formattedDate = openedDate.toLocaleDateString('en-US', {
               weekday: 'long', 
               month: 'short', 
               day: 'numeric'
         });

         const options = { hour: '2-digit', minute: '2-digit', hour12: true };
         const hourString = openedDate.toLocaleString('en-US', options);
         dateLabel.innerText = formattedDate.toUpperCase() + ' - ' + hourString;

         const selections = orderObject.checks[0].selections;

         var quantity = 0
         var itemNames = [];
         for (const selection of selections) {
               quantity += selection.quantity;
               itemNames.push(selection.displayName.toUpperCase());
         }

         const itemCountLabel = newOrdersCell.querySelector('[order="itemCountLabel"]');
         if (quantity == 1) {
               itemCountLabel.innerText = quantity + ' ITEM';
         } else {
               itemCountLabel.innerText = quantity + ' ITEMS';
         }

         const itemNamesLabel = newOrdersCell.querySelector('[order="itemNamesLabel"]');
         itemNamesLabel.innerText = itemNames.join(', ');

         const deliveryInfo = orderObject.deliveryInfo;

         const diningOptionLabel = newOrdersCell.querySelector('[order="diningOptionLabel"]');
         const addressLabel = newOrdersCell.querySelector('[order="addressLabel"]');
         if (deliveryInfo != null) {
               diningOptionLabel.innerText = 'DELIVERY';
               const address = deliveryInfo.address1;
               const apt = deliveryInfo.address2;
               const city = deliveryInfo.city;
               const state = deliveryInfo.state;
               const zipCode = deliveryInfo.zipCode;
               var addressString = address + ' - ' + city + ', ' + state + ' ' + zipCode;
               if (apt != null && apt != '') {
                  addressString = address + ', ' + apt + ' - ' + city + ', ' + state + ' ' + zipCode;
               }
               addressLabel.innerText = addressString.toUpperCase();
         } else {
               diningOptionLabel.innerText = 'PICK UP';
               const locationobject = order.get('locationobject');
               const address = locationobject.get('address');
               const city = locationobject.get('city');
               const state = locationobject.get('state');
               const zipcode = locationobject.get('zipcode');
               const addressString = address + ' - ' + city + ', ' + state + ' ' + zipcode;
               addressLabel.innerText = addressString.toUpperCase();
         }

         const favorite = order.get('favorite');
         const favoriteDiv = newOrdersCell.querySelector('[order="favoriteDiv"]');
         if (favorite == true) {
               favoriteDiv.style.display = 'flex';
         } else {
               favoriteDiv.style.display = 'none';
         }

         newOrdersCell.addEventListener('click', async () => {
               progressModal.style.display = 'flex';
               await Parse.Cloud.run('setQuickOrder', {'orderid': order.id});
               removeOrderCells()
               await setUpPreviousOrders();
               ordersModal.style.display = 'none';
               progressModal.style.display = 'none';

         });

         ordersParent.appendChild(newOrdersCell);
      }
   }

   function setPastCollectionCells() {
      const ordersCell = document.querySelector('[orderdate="cell"]');
      const ordersParent = ordersCell.parentNode;

      const ordersCells = [...document.querySelectorAll('[orderdate="cell"]')];

      for (let i = 0; i < previousOrders.length; i++) {

         const newOrdersCell = i === 0 ? ordersCells[0] : ordersCells[0].cloneNode(true);

         const order = previousOrders[i];

         const dateButton = newOrdersCell.querySelector('[orderdate="dateButton"]');
         const orderObject = order.get('orderObject');

         const openedDate = new Date(orderObject.openedDate);
         const formattedDate = openedDate.toLocaleDateString('en-US', {
               month: 'short', 
               day: 'numeric'
         });

         dateButton.innerText = formattedDate.toUpperCase();

         newOrdersCell.addEventListener('click', async () => {
               
               reorderOrder = order;
               setPastOrder();
               setPastOrderCellColor();

         });

         ordersParent.appendChild(newOrdersCell);
      }
   }

   function setPastOrderCellColor() {
      if (reorderOrder != null) {
         const cells = [...document.querySelectorAll('[orderdate="cell"]')];
         var index = 0;
         for (const cell of cells) {

            const order = previousOrders[index];
            const dateButton = cell.querySelector('[orderdate="dateButton"]');

            if (order != null) {
               if (reorderOrder.id == order.id) {
                  dateButton.style.backgroundColor = '#cf1d02';
                  dateButton.style.color = 'white';
               } else {
                  dateButton.style.backgroundColor = 'white';
                  dateButton.style.color = '#cf1d02';
               }
            }
            index += 1;
         }
      }
   }

   function removeOrderCells() {
      const cells = [...document.querySelectorAll('[order="cell"]')];
      var index = 0;
      for (const cell of cells) {
         if (index != 0) {
               cell.remove();
         }
         index += 1;
      }
   }

   startOrderButton.addEventListener('click', () => {
      const deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
      if (deliveryBool != null) {
         goToNextPage('/menu');
      } else {
         goToNextPage('/order');
      }
   });

   const chooseQuickOrderButton = document.getElementById('chooseQuickOrderButton');
   chooseQuickOrderButton.addEventListener('click', async () => {
      ordersModal.style.display = 'flex';
   });


   // MARK: - ORDER DETAILS
   const homeStartOrderSection = document.getElementById("homeStartOrderSection");
   const homeOrderDetailsSection = document.getElementById("homeOrderDetailsSection");
   const homeOrderInfoLabel = document.getElementById("homeOrderInfoLabel");

   async function setUpOrderDetails() {
      const deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
      const deliveryAddress = JSON.parse(sessionStorage.getItem('deliveryAddress'));
      const location = JSON.parse(localStorage.getItem('location'));

      if (deliveryBool != null) {

         homeOrderDetailsSection.style.display = 'block';
         homeStartOrderSection.style.display = 'none';
         
         await setHomeOrderDetailsCart();

         if (deliveryBool) {
               if (deliveryAddress != null) {
                  const address = deliveryAddress.addressLabel.toUpperCase();
                  const city = deliveryAddress.city.toUpperCase();
                  const stateCode = deliveryAddress.stateCode.toUpperCase();
                  const postalCode = deliveryAddress.postalCode.toUpperCase();
                  homeOrderInfoLabel.innerText = 'DELIVERY: \n' + address + '\n' + city + ', ' + stateCode + ' ' + postalCode;
               } else {
                  sessionStorage.setItem('deliveryBool', 'false');
                  if (location != null) {
                     const address = location.address.toUpperCase();
                     const city = location.city.toUpperCase();
                     const stateCode = location.state.toUpperCase();
                     const postalCode = location.zipcode.toUpperCase();
                     homeOrderInfoLabel.innerText = 'PICK UP:\n' + address + '\n' + city + ', ' + stateCode + ' ' + postalCode;
                  }
               }
         } else {
               if (location != null) {
                  const address = location.address.toUpperCase();
                  const city = location.city.toUpperCase();
                  const stateCode = location.state.toUpperCase();
                  const postalCode = location.zipcode.toUpperCase();
                  homeOrderInfoLabel.innerText = 'PICK UP:\n' + address + '\n' + city + ', ' + stateCode + ' ' + postalCode;
               }
         }
      }
   }

   async function setHomeOrderDetailsCart() {
      const homeCartDiv = document.getElementById("homeCartDiv");
      const homeCartCountLabel = document.getElementById("homeCartCountLabel");
      const currentUser = Parse.User.current();
      if (currentUser != null && isMobileDevice() == false) {
         await currentUser.fetch();
         const orderCheck = currentUser.get('orderCheck');
         const selections = orderCheck.checks[0].selections;
         if (selections.length == 0) {                
            homeCartDiv.style.display = 'none';
         } else {
            homeCartDiv.style.display = 'flex';
            var quantity = 0;
            for (const selection of selections) {
               quantity += selection.quantity;
            }
            homeCartCountLabel.innerText = '( ' + quantity + ' )';
         }
      } else {
         homeCartDiv.style.display = 'none';
      }
   }

   setUpPage();

   // MARK: - BUTTONS
   var promoOffer;
   async function loadPromoOffers() {
      try {
         const promoOffers = await Parse.Cloud.run('loadPromoOffers', {});
         if (promoOffers.length > 0) {
            const randomIndex = getRandomIntInclusive(0, promoOffers.length - 1);
            promoOffer = promoOffers[randomIndex];
            setPromoOffer(promoOffer);
         }
     } catch(e) {
         alert(e.message); 
     }
   }

   function setPromoOffer(promoOffer) {
      const imageUrl = promoOffer.get('image').url();
      rightTopPromoImage.src = imageUrl;
   }

   function getRandomIntInclusive(min, max) {
      const minCeiled = Math.ceil(min);
      const maxFloored = Math.floor(max);
      return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
    }


   homeLeftPromoButton.addEventListener('click', function() {
      const itemGuid = 'bedfbc75-a3ac-4e82-9e46-c86d0abea42d';
      const groupGuid = '14ac8b8b-cd8c-44a1-ba7f-045777c3c391';
      goToMenuItem(itemGuid, groupGuid);
   });

   homeTopRightPromoButton.addEventListener('click', function() {
      //const itemGuid = '0842f225-8292-4421-9ccc-910fc2357f1d';
      //const groupGuid = 'd63ab0f1-74f8-45a9-bd47-963b163b5506';
      if (promoOffer != null) {
         const identifier = promoOffer.get('identifier');
         if (identifier == 'menuitem') {
            const itemGuid = promoOffer.get('itemGuid');
            const groupGuid = promoOffer.get('groupGuid');
            goToMenuItem(itemGuid, groupGuid);
         }
         if (identifier == "menugroup") {
            const groupGuid = promoOffer.get('groupGuid');
            goToMenuGroup(groupGuid);
         }
      }
   });

   leftPromoImage.addEventListener('click', function() {
      const itemGuid = 'bedfbc75-a3ac-4e82-9e46-c86d0abea42d';
      const groupGuid = '14ac8b8b-cd8c-44a1-ba7f-045777c3c391';
      goToMenuItem(itemGuid, groupGuid);
   });

   rightTopPromoImage.addEventListener('click', function() {
      if (promoOffer != null) {
         const identifier = promoOffer.get('identifier');
         if (identifier == 'menuitem') {
            const itemGuid = promoOffer.get('itemGuid');
            const groupGuid = promoOffer.get('groupGuid');
            goToMenuItem(itemGuid, groupGuid);
         }
         if (identifier == "menugroup") {
            const groupGuid = promoOffer.get('groupGuid');
            goToMenuGroup(groupGuid);
         }
      }
   });

   rightBottomPromoImage.addEventListener('click', function() {
       goToNextPage('/order');
   });

   async function goToMenuItem(itemGuid, groupGuid) { 
      progressModal.style.display = 'flex';
      const menuItem = await getToastItem(groupGuid, itemGuid);
      const menuCategory = await getToastGroup(groupGuid);
      progressModal.style.display = 'none';
      sessionStorage.setItem('category', JSON.stringify(menuCategory));
      sessionStorage.setItem('menuItem', JSON.stringify(menuItem));
      goToNextPage('/item');
   }

   async function goToMenuGroup(groupGuid) { 
      progressModal.style.display = 'flex';
      const menuCategory = await getToastGroup(groupGuid);
      progressModal.style.display = 'none';
      sessionStorage.setItem('category', JSON.stringify(menuCategory));
      goToNextPage('/menu');
   }

   homeBottomAccountButton.addEventListener('click', function() {
      const currentUser = Parse.User.current();
      if (currentUser != null) {
         const anonymous = currentUser.get('anonymous');
         if (anonymous == false) {
            goToNextPage('/rewards');
         } else {
            goToNextPage('/create-account');
         }
      } else {
         goToNextPage('/create-account');
      }
   });

   homeBottomOrderButton.addEventListener('click', function() {
      const deliveryBool = JSON.parse(sessionStorage.getItem('deliveryBool'));
      if (deliveryBool != null) {
         goToNextPage('/menu');
      } else {
         goToNextPage('/order');
      }
   });

   // MARK: - VERIFY PHONE
   var phoneNumber;
   var codeSent = false;

   $("#verifyForm").submit(() => { return false; });

   function setUpPhoneVerify(phone) {
       phoneNumber = phone;
       verifyPhoneTextField.value = formatPhone(phone);
       if (initialoffer != null) {
           verifyTopLabel.innerText = 'EARN ' + initialamount + '% OFF ONE ORDER - VALID ONE TIME PER CUSTOMER';
       }
       verifyModal.style.display = 'flex';
   }

   resendCodeButton.addEventListener('click', async () => {
       resetView();
   });

   verifySmsButton.addEventListener('click', async () => {
       if (codeSent === false) {
           phoneNumber = verifyPhoneTextField.value.replace(/\D/g, '');
           var error = '';
           if (phoneNumber.length != 10) {
               error = 'Please enter full 10 digits';
           }
           if (error != '') {
               alert(error);
           } else {
               checkPhoneNumber(phoneNumber);
           }
       } else {
           // verify with code
           const code = verifyPhoneTextField.value;
           var error = '';
           if (code.length != 6) {
               error = 'Please enter 6 digit code sent in SMS';
           }
           if (error != '') {
               alert(error);
           } else {
               verifyPhoneNumber(phoneNumber, code);
           }
       }
   });

   closeVerifyButton.addEventListener('click', async () => {
       verifyModal.style.display = 'none';
   });

   async function verifyPhoneNumber(phone, code) {
       progressModal.style.display = 'flex';
       try {
           const status = await Parse.Cloud.run('verifyTextCodeWeb', {'phone':phone, 'code': code});
           if (status === 'approved') {
               const request = {'phone': phoneNumber, 'initialamount': initialamount, 'locationid': locationId};
               await Parse.Cloud.run('updateUserVerificationAndRefferal', request);

               const currentUser = Parse.User.current();
               if (currentUser != null) {
                   await currentUser.fetch();
               }

               goToNextPage('/profile');
           } else {
               alert('Code failed please try again');
           }
       } catch(e) {
           alert(e.message);
       }
       progressModal.style.display = 'none';
   }
   
   async function checkPhoneNumber(phone) {
       progressModal.style.display = 'flex';
       try {
           const available = await Parse.Cloud.run('checkPhoneNumber', {"phone":phone});
           if (available == true) {
               await sendCode(phone);
           } else {
               alert('Offer already redeemed by this phone number');
           }
       } catch(e) {
           alert(e.message);
       }
       progressModal.style.display = 'none';
   }
   
   async function sendCode(phone) {
       const status = await Parse.Cloud.run('sendTextVerificationWeb', {"phone":phone});
       if (status === "pending") {
           verifyPhoneTextField.value = '';
           verifyPhoneTextField.placeholder = 'Enter Code';
           verifyTopLabel.innerText = 'ENTER 6 DIGIT CODE'
           resendCodeButton.style.display = 'block';
           verifySmsButton.value = 'VERIFY';
           verifyPhoneTextField.focus();
           codeSent = true;
       } else {
           alert('Code failed to send');
       }
   }
   
   async function resetView() {
       verifyPhoneTextField.value = formatPhone(phoneNumber);
       verifyTopLabel.innerText = 'TO RECEIVE THIS OFFER YOU MUST VERIY PHONE NUMBER VIA SMS';
       resendCodeButton.style.display = 'none';
       verifySmsButton.value = 'SEND SMS CODE';
       codeSent = false;
       
       if (initialoffer != null) {
           verifyTopLabel.innerText = 'EARN ' + initialamount + '% OFF ONE ORDER';
       }
       if (referralid != null) {
        verifyTopLabel.innerText = 'EARN ' + initialamount + '% OFFER FROM YOUR FRIEND';
       }
   }
   
});