import Parse from "/server/parse-server.js";
import locationId from "/server/parse-location.js";
import { LocationObject } from "/server/parse-location.js";
import { goToNextPage } from "/reusable/navigation.js";

export async function orderQuickOrder(order) {
    try {
        await Parse.Cloud.run('orderQuickOrder', {'orderid': order.id});
        const currentUser = Parse.User.current();
        await currentUser.fetch();
        const locationObject = order.get('locationobject');
        const deliveryInfo = order.get('orderObject').deliveryInfo;
        if (deliveryInfo != null) {
            await setStorageDelivery(order, locationObject);
        } else {
            await setStoragePickUp(locationObject);
        }
    } catch (e) {
        alert(e.message);
    }
}

async function setStoragePickUp(location) {
    const locationObject = {
        id: location.id,
        city: location.get('city'),
        address: location.get('address'),
        state: location.get('state'),
        zipcode: '' + location.get('zipcode'),
        phone: location.get('phonenumber')
    }

    const geoPoint = location.get('location');
    const pickupObject = {
        postalCode: '' + location.get('zipcode'),
        latitude: geoPoint.latitude,
        longitude: geoPoint.longitude
    }

    localStorage.setItem('location', JSON.stringify(locationObject));
    sessionStorage.setItem('pickupAddress', JSON.stringify(pickupObject));
    sessionStorage.setItem('deliveryBool', 'false');

    const currentUser = Parse.User.current();
    if (currentUser != null) {
        await Parse.Cloud.run('setUserLocation', {'locationid': location.id});
        await currentUser.fetch();
    }

    const restaurantGuid = location.get('guid');
    const toastLocation = await Parse.Cloud.run('getToastLocation', {'restaurantGuid': restaurantGuid});
    localStorage.setItem('toastLocation', JSON.stringify(toastLocation.get('object')));
    
    const toastOrderSchedule = await Parse.Cloud.run('getToastOrderSchedule', {'restaurantGuid': restaurantGuid});
    localStorage.setItem('toastOrderSchedule', JSON.stringify(toastOrderSchedule.get('object')));
}

async function setStorageDelivery(order, deliveryLocation) {

    const locationObject = {
        id: deliveryLocation.id,
        city: deliveryLocation.get('city'),
        address: deliveryLocation.get('address'),
        state: deliveryLocation.get('state'),
        zipcode: '' + deliveryLocation.get('zipcode'),
        phone: deliveryLocation.get('phonenumber')
    }

    const deliveryInfo = order.get('orderObject').deliveryInfo;
    var deliveryAddress = {
        'addressLabel': deliveryInfo.address1,
        'postalCode' : deliveryInfo.zipCode,
        'city': deliveryInfo.city,
        'stateCode': deliveryInfo.state,
        'instructions': deliveryInfo.notes
    }

    const apt = deliveryInfo.address2;
    if (apt != null && apt != '') {
        deliveryAddress.apt = apt;
    } 

    const addressid = order.get('addressid');
    if (addressid != null) {
        deliveryAddress.id = addressid;
    }

    localStorage.setItem('location', JSON.stringify(locationObject));
    sessionStorage.setItem('deliveryAddress', JSON.stringify(deliveryAddress));
    sessionStorage.setItem('deliveryBool', 'true');

    const currentUser = Parse.User.current();
    if (currentUser != null) {
        await Parse.Cloud.run('setUserLocation', {'locationid': deliveryLocation.id});
        await currentUser.fetch();
    }

    const restaurantGuid = deliveryLocation.get('guid');
    const toastLocation = await Parse.Cloud.run('getToastLocation', {'restaurantGuid': restaurantGuid});
    localStorage.setItem('toastLocation', JSON.stringify(toastLocation.get('object')));

    const toastOrderSchedule = await Parse.Cloud.run('getToastOrderSchedule', {'restaurantGuid': restaurantGuid});
    localStorage.setItem('toastOrderSchedule', JSON.stringify(toastOrderSchedule.get('object')));
    //goToNextPage('/checkout');
}